import browserLang from 'browser-lang'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import config from '../config'

export function getPageLanguage(): string {
  if (!isBrowser()) return 'en'

  const detected =
    localStorage.getItem('language') ||
    browserLang({
      languages: ['en', 'zh'],
      fallback: 'en',
    }) ||
    'en'

  return detected
}

export const isBrowser = () =>
  typeof window !== 'undefined' &&
  typeof document !== 'undefined' &&
  typeof navigator !== 'undefined'

export function getLocalizationId(item: any): boolean | number {
  return item.attributes?.localizations?.data?.[0]?.id
}

export function handleImgUrl(url = ''): string {
  if (!url) return ''

  // Handle both full URLs and relative paths
  const ossUrl = 'chuxin-strapi-media.oss-cn-beijing.aliyuncs.com'
  const cdnUrl = 'media.monadventures.com'

  if (url.startsWith('http')) {
    // Replace OSS domain with CDN domain for full URLs
    return url.replace(ossUrl, cdnUrl)
  }

  // For relative paths, first check if we have an imageUrl config
  if (!config.imageUrl) {
    console.error('Image URL configuration is missing')
    return url
  }

  // If the config imageUrl contains OSS domain, replace it with CDN
  const baseUrl = config.imageUrl.replace(ossUrl, cdnUrl)
  return `${baseUrl}${url}`
}

export function resolveServerImg(
  img?: ServerImageData,
): IGatsbyImageData | null {
  if (!img?.data?.attributes) {
    return null
  }

  try {
    return {
      layout: 'constrained',
      width: img.data.attributes.width,
      height: img.data.attributes.height,
      images: {
        sources: [
          {
            srcSet: handleImgUrl(img.data.attributes.url),
            type: 'medium',
          },
        ],
        fallback: {
          src: handleImgUrl(img.data.attributes.url),
        },
      },
    }
  } catch (error) {
    console.error('Failed to resolve server image:', error)
    return null
  }
}

export function formatTime(t: string) {
  if (typeof t !== 'string') {
    return t
  }
  const tIndex = t.indexOf('T')
  let s = t
  if (tIndex > -1) {
    s = t.slice(0, tIndex)
  }
  return s.replaceAll('-', '.')
}
